import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
  useMemo,
  useRef,
} from 'react'

import { DndProvider, useDrag, useDrop } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

import {
  createBrowserRouter,
  RouterProvider,
  Link,
  Outlet,
  useNavigate,
  useParams,
} from 'react-router-dom'

import {
  Autocomplete,
  Avatar,
  Button,
  Box,
  Container,
  createTheme,
  useTheme,
  CssBaseline,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Popover,
  TextField,
  ThemeProvider,
  Toolbar,
  Typography,
  useMediaQuery,
} from '@mui/material'

import ViewModuleIcon from '@mui/icons-material/ViewModule'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'
import Brightness4Icon from '@mui/icons-material/Brightness4'
import Brightness7Icon from '@mui/icons-material/Brightness7'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'

import {
  GoogleOAuthProvider,
  GoogleLogin,
  googleLogout,
} from '@react-oauth/google'

function randomUID(length) {
  let result = ''
  const characters = '0123456789abcdef'
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length))
  }
  return result
}
function quotePlus(str) {
  return encodeURIComponent(str.replace(/ /g, '-').replace(/[\?\!\:]/g, ''))
}

const JP_ARCS = [
  { name: 'Childhood to YA' },
  { name: 'Death and Grieving', desc: 'Dad death moving forward' },
  { name: 'Drugs' },
  { name: 'LSD', parent: 'Drugs' },
  { name: 'Mushrooms', parent: 'Drugs' },
  { name: 'Ayahuasca', parent: 'Drugs' },
  {
    name: 'The Hunger',
    parent: 'Drugs',
    note: 'throughline of psych arc -- "that\'s the thing that holds it"',
  },
  { name: 'Relationships', desc: 'Romance and friendships' },
  {
    name: 'Daniel',
    parent: 'Relationships',
    note: 'maybe break into two chunks w/listlessness in middle',
  },
  { name: 'Will', parent: 'Relationships' },
  { name: 'Ethan', parent: 'Relationships' },
  { name: 'West Coast Alex', parent: 'Relationships' },
  { name: 'East Coast Alex', parent: 'Relationships' },
]

const JP_THEMES = {
  'Unrequited Love': {
    desc: "The sex or attention you're offered isn't the sex you want",
    nagle: 'Loneliness... ',
  },
  'Deeply Broken System': {
    desc: 'eg Sodium ... not narcissistic',
  },
  'Spicy Attachment': {
    desc: 'You get to be as unreasonable as your mother and people will still love you',
    janine:
      "Mom doesn't show gratitude in the same way ... more shame in her system.",
  },
  "Don't be a Little Bitch": {
    desc: '(Cancer) - you need to hold yourself together to be here',
  },
}

class InsistenceDB {
  async load() {
    const res = await fetch('/api/db')
    this.log = []
    this.db = await res.json()
  }

  applyChange(ch) {
    if (ch.op === 'put') {
      let obj = this.db
      for (const part of ch.path.slice(0, ch.path.length - 1)) {
        if (!(part in obj)) {
          obj[part] = {}
        }
        // break object equality
        obj[part] = { ...obj[part] }
        obj = obj[part]
      }
      obj[ch.path[ch.path.length - 1]] = ch.data
    } else if (ch.op === 'delete') {
      let obj = this.db
      for (const part of ch.path.slice(0, ch.path.length - 1)) {
        obj[part] = { ...obj[part] }
        obj = obj[part]
      }
      delete obj[ch.path[ch.path.length - 1]]
    } else {
      console.warn('unknown change', ch)
    }

    this.log.push(ch)

    if (this.onChange) {
      console.log('fire change!')
      this.onChange(this.log.length)
    }
  }

  async pushChange(obj) {
    // 1. Optimistic update
    this.applyChange(obj)

    // 2. Apply
    const res = await fetch('/api/pushChange', {
      method: 'post',
      body: JSON.stringify(obj),
    })

    // 3. Return
    return await res.json()
  }
}

const DataProvider = React.createContext({
  insistence: null,
})

const DataLoader = ({ children }) => {
  const [insistence, setInsistence] = useState()
  const [authError, setAuthError] = useState()
  const [userInfo, setUserInfo] = useState()

  const [changes, setChanges] = useState(0)

  const oneOff = useRef()

  useEffect(() => {
    const load = async () => {
      try {
        const idb = new InsistenceDB()
        await idb.load()
        idb.onChange = (idx) => {
          // force reload
          setChanges(idx)
        }
        window.insistence = idb
        setInsistence(idb)
      } catch {
        setAuthError('invalid auth')
      }
    }

    if (!oneOff.current) {
      oneOff.current = true
      load()
    }
  }, [userInfo, authError])

  useEffect(() => {
    if (userInfo && authError) {
      // user info set! try fetching db again
      oneOff.current = false
      setAuthError(false)
    }
  }, [userInfo, authError])

  if (authError) {
    return <SignIn setUserInfo={setUserInfo} />
  }

  if (!insistence) {
    return <i>Loading...</i>
  }

  return (
    <DataProvider.Provider value={{ insistence, changes }}>
      {children}
    </DataProvider.Provider>
  )
}

const drawerWidth = 240

const SignIn = ({ setUserInfo }) => {
  return (
    <Container maxWidth="sm" sx={{ p: 3 }}>
      <GoogleLogin
        onSuccess={(credentialResponse) => {
          console.log(credentialResponse)

          const signIn = async (token) => {
            const res = await fetch('/google-sign-in', {
              method: 'post',
              body: JSON.stringify({ token }),
            })
            const info = await res.json()

            setUserInfo(info)

            console.log(info)
          }

          signIn(credentialResponse.credential)
        }}
        onError={() => {
          console.log('Login Failed')
        }}
      />
    </Container>
  )
}

const ONLY_COLOR = false

const AllCards = () => {
  const { insistence } = useContext(DataProvider)

  const sorted = useMemo(
    () =>
      Object.entries(insistence.db.frag || {})
        .filter((x) => !x[1].skip)
        .sort((x, y) => (x[1].order > y[1].order ? 1 : -1)),
    []
  )

  return (
    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
      {sorted.map(([k, v]) => (
        <Link key={k} to={`frag/${k}`}>
          <MetaFragment title={k} {...v} />
        </Link>
      ))}
    </Box>
  )
}

const DraggableListItem = ({ children, name }) => {
  const [{ dragging }, dragRef] = useDrag(() => {
    return {
      type: 'FRAGMENT',
      item: { name },
      collect: (monitor) => ({
        dragging: monitor.isDragging(),
      }),
    }
  }, [])

  return <ListItem ref={dragRef}>{children}</ListItem>
}

const DraggableTOCItem = ({ name, onDrop, seqId, onDelete }) => {
  const [{ isDragging }, drag, preview] = useDrag({
    type: 'TOC-ITEM',
    item: { name, seqId },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  })

  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: ['FRAGMENT', 'TOC-ITEM'],
      drop: (item, monitor) => {
        console.log('DROP OVER!', item)
        onDrop(item.name, item.seqId, seqId)
      },
      collect: (monitor) => {
        return { isOver: monitor.isOver() }
      },
    }),
    [onDrop]
  )

  return (
    <Box ref={drop}>
      <Box
        ref={preview}
        sx={{
          opacity: isDragging ? 0.5 : 1,
          borderTop: isOver ? '1px solid red' : '1px solid rgba(1,1,1,0)',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <IconButton ref={drag}>
          <DragIndicatorIcon />
        </IconButton>

        <Typography
          onClick={() => {
            window.location.hash = name
          }}
          sx={{ flexGrow: 1 }}
        >
          {name}
        </Typography>

        <IconButton onClick={onDelete}>
          <DeleteIcon />
        </IconButton>
      </Box>
    </Box>
  )
}

const FragmentList = () => {
  const { insistence } = useContext(DataProvider)
  const { fragId } = useParams()

  const sorted = useMemo(
    () =>
      Object.entries(insistence.db.frag || {})
        .filter((x) => !x[1].skip)
        .sort((x, y) => (x[1].order > y[1].order ? 1 : -1)),
    []
  )

  return (
    <>
      {sorted.map(([k, v]) => (
        <DraggableListItem key={k} name={k}>
          <ListItemButton
            component={Link}
            to={`frag/${k}`}
            selected={k === fragId}
            title={k}
            {...v}
          >
            <ListItemText primary={k} />
          </ListItemButton>
        </DraggableListItem>
      ))}
    </>
  )
}

const FragmentInfoBar = ({ uid }) => {
  const { insistence } = useContext(DataProvider)

  const { color, rmo, nagle, janine } = insistence.db.frag[uid]
  const [col, setCol] = useState(color)
  const [rm, setRM] = useState(rmo)
  const [na, setNA] = useState(nagle)
  const [jp, setJP] = useState(janine)

  useEffect(() => {
    setCol(color)
    setRM(rmo)
    setNA(nagle)
    setJP(janine)
  }, [uid, color, rmo, nagle, janine])

  const hasChange = color !== col || rmo !== rm || nagle !== na

  return (
    <Box m={2}>
      <TextField
        sx={{ pb: 2 }}
        variant="standard"
        label="color"
        value={col}
        onChange={(e) => setCol(e.target.value)}
      />
      <TextField
        sx={{ pb: 2 }}
        multiline
        variant="standard"
        label="rmo"
        value={rm}
        onChange={(e) => setRM(e.target.value)}
      />
      <TextField
        sx={{ pb: 2 }}
        multiline
        variant="standard"
        label="nagle"
        value={na}
        onChange={(e) => setNA(e.target.value)}
      />
      <TextField
        sx={{ pb: 2 }}
        multiline
        variant="standard"
        label="janine"
        value={jp}
        onChange={(e) => setJP(e.target.value)}
      />

      <Button
        disabled={!hasChange}
        variant="contained"
        onClick={() => {
          if (color !== col) {
            insistence.pushChange({
              op: 'put',
              path: ['frag', uid, 'color'],
              data: col,
            })
          }
          if (rmo !== rm) {
            insistence.pushChange({
              op: 'put',
              path: ['frag', uid, 'rmo'],
              data: rm,
            })
          }
          if (nagle !== na) {
            insistence.pushChange({
              op: 'put',
              path: ['frag', uid, 'nagle'],
              data: na,
            })
          }
          if (janine !== jp) {
            insistence.pushChange({
              op: 'put',
              path: ['frag', uid, 'janine'],
              data: jp,
            })
          }
        }}
      >
        Update
      </Button>
    </Box>
  )
}

const Fragment = ({ uid }) => {
  const { segs } = useContext(DataProvider).insistence.db.frag[uid]

  return (
    <>
      <h1 id={uid}>{uid}</h1>
      {segs.map(({ text }, i) => (
        <Typography variant="writing" key={i}>
          {text}
        </Typography>
      ))}
    </>
  )
}

const FragmentPage = () => {
  const { fragId } = useParams()
  const name = fragId

  useEffect(() => {
    // Scroll up on load
    document.scrollingElement.scrollTop = 0
  }, [name])

  return (
    <>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Fragment uid={fragId} />
      </Box>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="right"
        open={true}
      >
        <Toolbar />
        <Divider />
        <FragmentInfoBar uid={name} />
      </Drawer>
    </>
  )
}

const MetaFragment = ({ title, segs, color, rmo, nagle, active, onClick }) => {
  const [{ dragging }, dragRef] = useDrag(() => {
    return {
      type: 'FRAGMENT',
      item: { title },
      collect: (monitor) => ({
        dragging: monitor.isDragging(),
      }),
    }
  }, [])

  const wc = useMemo(
    () => segs.reduce((acc, x) => acc + x.text.split(' ').length, 0),
    [segs]
  )

  if (!color && ONLY_COLOR) {
    return ''
  }

  return (
    <Typography
      variant="body2"
      div
      ref={dragRef}
      onClick={onClick}
      style={{
        backgroundColor: color,
        color: color ? 'black' : undefined,
        width: 200,
        height: 100,
        display: 'inline-block',
        margin: 5,
        overflow: 'hidden',
        border: '1px dashed #777',
      }}
    >
      {title} ({wc})<div className="nagle">{nagle || ''}</div>
      <div className="rmo">{rmo}</div>
    </Typography>
  )
}

const LHS = () => {
  const [collapsed, setCollapsed] = useState({})
  const { insistence } = useContext(DataProvider)

  const { arcId: arcIdRaw, fragId } = useParams()

  const arcId = useMemo(
    () => (arcIdRaw ? arcIdRaw.split('-')[arcIdRaw.split('-').length - 1] : ''),
    [arcIdRaw]
  )

  const colorMode = React.useContext(ColorModeContext)
  const theme = useTheme()
  const navigate = useNavigate()

  const arcs = useMemo(() => {
    return Object.entries(insistence.db.arc || {})
  }, [insistence.db.arc])

  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          width: drawerWidth,
          boxSizing: 'border-box',
        },
      }}
      variant="permanent"
      anchor="left"
    >
      <Box sx={{ display: 'flex' }}>
        <Button
          onClick={() => {
            const logout = async () => {
              await fetch('/api/sign-out')
              googleLogout()
              window.location.reload()
            }
            logout()
          }}
        >
          <Avatar
            sx={{ mx: 2 }}
            src={insistence.db.user[insistence.db.whoami].picture}
          />
          Sign Out
        </Button>

        <IconButton
          sx={{ ml: 1 }}
          onClick={colorMode.toggleColorMode}
          color="inherit"
        >
          {theme.palette.mode === 'dark' ? (
            <Brightness7Icon />
          ) : (
            <Brightness4Icon />
          )}
        </IconButton>
      </Box>

      <List component="nav" dense>
        <ListSubheader>
          <IconButton disabled sx={{ mr: 1 }}>
            <ArrowDropDownIcon />
          </IconButton>
          Maps
        </ListSubheader>

        <ListItem>
          <ListItemButton selected={!arcId && !fragId} component={Link} to="/">
            <ListItemIcon>
              <ViewModuleIcon />
            </ListItemIcon>
            <ListItemText primary="All Cards" />
          </ListItemButton>
        </ListItem>

        <ListSubheader>
          <Box sx={{ display: 'flex' }}>
            <IconButton
              onClick={() => {
                setCollapsed({
                  ...collapsed,
                  arcs: !collapsed.arcs,
                })
              }}
              sx={{ mr: 1 }}
            >
              {collapsed.arcs ? <ArrowRightIcon /> : <ArrowDropDownIcon />}
            </IconButton>
            <Box sx={{ flexGrow: 1 }}>Arcs</Box>

            <IconButton
              onClick={() => {
                // Create a new arc and navigate to it!
                const uid = randomUID(8)
                // init as empty
                insistence.pushChange({
                  op: 'put',
                  path: ['arc', uid],
                  data: {},
                })
                // & nav there
                navigate(`/arc/-${uid}`)
              }}
            >
              <AddIcon />
            </IconButton>
          </Box>
        </ListSubheader>

        {collapsed.arcs
          ? ''
          : arcs.map(([uid, { title }]) => (
              <ListItem
                key={uid}
                selected={uid === arcId}
                secondaryAction={
                  <IconButton
                    edge="end"
                    onClick={() => {
                      insistence.pushChange({
                        op: 'delete',
                        path: ['arc', uid],
                      })
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                }
              >
                <ListItemButton
                  component={Link}
                  to={`arc/${quotePlus(title || 'Untitled')}-${uid}`}
                >
                  <ListItemText primary={title} />
                </ListItemButton>
              </ListItem>
            ))}

        <ListSubheader>
          <IconButton
            onClick={() => {
              setCollapsed({
                ...collapsed,
                fragments: !collapsed.fragments,
              })
            }}
            sx={{ mr: 1 }}
          >
            {collapsed.fragments ? <ArrowRightIcon /> : <ArrowDropDownIcon />}
          </IconButton>
          Fragments
        </ListSubheader>

        {collapsed.fragments ? '' : <FragmentList />}

        <Divider />
      </List>
    </Drawer>
  )
}

const Main = () => {
  const [active, setActive] = useState()
  const [cafe, setCafe] = useState()

  const { insistence } = useContext(DataProvider)

  useEffect(() => {
    if (active && cafe) {
      setCafe(false)
    }
  }, [active])

  return (
    <>
      <LHS
        active={active}
        setActive={setActive}
        cafe={cafe}
        setCafe={setCafe}
      />
      <Outlet />
    </>
  )
}

const ColorModeContext = React.createContext({ toggleColorMode: () => {} })

const FragChooser = ({ onSelect, helpText }) => {
  const [inputValue, setInputValue] = useState('')
  const [anchorEl, setAnchorEl] = useState()
  const textRef = useRef()

  const { insistence } = useContext(DataProvider)

  useEffect(() => {
    if (anchorEl && textRef.current && textRef.current.querySelector('input')) {
      textRef.current.querySelector('input').focus()
    }
  }, [anchorEl])

  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: ['FRAGMENT', 'TOC-ITEM'],
      drop: (item, monitor) => {
        console.log('DROP!', item, monitor, monitor.getItemType())
        onSelect(item.name, item.seqId)
      },
      collect: (monitor) => {
        return { isOver: monitor.isOver() }
      },
    }),
    [onSelect]
  )

  const sorted = useMemo(
    () =>
      Object.entries(insistence.db.frag || {})
        .filter((x) => !x[1].skip)
        .sort((x, y) => (x[1].order > y[1].order ? 1 : -1)),
    []
  )

  return (
    <Box
      ref={drop}
      sx={{
        flexGrow: '1',
        borderTop: isOver ? '1px solid red' : '1px solid rgba(1,1,1,0)',
      }}
    >
      <Typography>
        <i>{helpText}</i>
      </Typography>
      <IconButton
        onClick={(e) => {
          setAnchorEl(e.currentTarget)
        }}
      >
        <AddIcon />
      </IconButton>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        open={!!anchorEl}
        keepMounted
        onClose={() => setAnchorEl()}
      >
        <Box>
          <Autocomplete
            disableClearable
            options={sorted}
            sx={{ width: 300 }}
            getOptionLabel={(opt) => (opt ? opt[0] : '')}
            renderInput={(params) => (
              <TextField {...params} ref={textRef} label="Fragment" />
            )}
            autoComplete
            autoHighlight
            value={undefined}
            inputValue={inputValue}
            onChange={(event, value, reason) => {
              console.log(reason)
              if (reason === 'selectOption') {
                console.log('SELECT!')
                setInputValue('')
                onSelect(value[0])
              } else {
                console.warn('unknown option', event, value, reason)
              }
            }}
            onInputChange={(event, newInputValue) => {
              if (!event || event.type === 'blur') {
              } else if (event) {
                setInputValue(newInputValue)
              }
            }}
          />
        </Box>
      </Popover>
    </Box>
  )
}

const Arc = () => {
  const { arcId: arcIdRaw } = useParams()
  const arcId = useMemo(
    () => arcIdRaw.split('-')[arcIdRaw.split('-').length - 1],
    [arcIdRaw]
  )
  const navigate = useNavigate()

  const { insistence } = useContext(DataProvider)

  const [title, setTitle] = useState(
    ((insistence.db.arc || {})[arcId] || {}).title || ''
  )

  const seqRaw = ((insistence.db.arc || {})[arcId] || {}).seq

  useEffect(() => {
    setTitle(((insistence.db.arc || {})[arcId] || {}).title || '')
  }, [arcId])

  // Convert to a basic sequence...
  const seq = useMemo(() => {
    const out = []

    let nxtSeqId = (seqRaw || {})._HEAD
    while (nxtSeqId) {
      const nxtFrag = seqRaw[nxtSeqId]
      out.push([nxtSeqId, nxtFrag.id])
      nxtSeqId = nxtFrag._NEXT
    }

    return out
  }, [seqRaw])

  const onDrop = useCallback(
    (name, seqId, insertBeforeSeqId) => {
      console.log('DROP', name, seqId, insertBeforeSeqId)

      if (!seqId) {
        // New node!
        const newId = randomUID(6)

        // 1. Create and point to next if given
        insistence.pushChange({
          op: 'put',
          path: ['arc', arcId, 'seq', newId],
          data: {
            id: name,
            _NEXT: insertBeforeSeqId,
          },
        })

        // 2. Point to us from whatever node used to point to insertBeforeSeqId
        if (seq.length === 0 || seqRaw._HEAD === insertBeforeSeqId) {
          //set head here
          insistence.pushChange({
            op: 'put',
            path: ['arc', arcId, 'seq', '_HEAD'],
            data: newId,
          })
        } else {
          let curId = seqRaw._HEAD
          while (curId) {
            const curNode = seqRaw[curId]

            if (curNode._NEXT === insertBeforeSeqId) {
              insistence.pushChange({
                op: 'put',
                path: ['arc', arcId, 'seq', curId, '_NEXT'],
                data: newId,
              })
              break
            }

            curId = curNode._NEXT
          }
        }
      } else {
        // Existing item!
        const existingNode = seqRaw[seqId]

        // 1. Figure out its new position. We're inserting it
        // before a node, so we need to eat whatever pointer had gone to that node.

        if (seqRaw._HEAD === insertBeforeSeqId) {
          insistence.pushChange({
            op: 'put',
            path: ['arc', arcId, 'seq', '_HEAD'],
            data: seqId,
          })
        } else {
          let curId = seqRaw._HEAD
          while (curId) {
            const curNode = seqRaw[curId]
            if (curNode._NEXT === insertBeforeSeqId) {
              insistence.pushChange({
                op: 'put',
                path: ['arc', arcId, 'seq', curId, '_NEXT'],
                data: seqId,
              })
              break
            }
            curId = curNode._NEXT
          }
        }

        // 2. The node that used to point to us should now point _NEXT
        if (seqRaw._HEAD === seqId) {
          insistence.pushChange({
            op: 'put',
            path: ['arc', arcId, 'seq', '_HEAD'],
            data: existingNode._NEXT,
          })
        } else {
          let curId = seqRaw._HEAD
          while (curId) {
            const curNode = seqRaw[curId]

            if (curNode._NEXT === seqId) {
              insistence.pushChange({
                op: existingNode._NEXT ? 'put' : 'delete',
                path: ['arc', arcId, 'seq', curId, '_NEXT'],
                data: existingNode._NEXT,
              })

              break
            }

            curId = curNode._NEXT
          }
        }

        // 3. FINALLY: we set our pointer
        insistence.pushChange({
          op: insertBeforeSeqId ? 'put' : 'delete',
          path: ['arc', arcId, 'seq', seqId, '_NEXT'],
          data: insertBeforeSeqId,
        })
      }
    },
    [seqRaw, seq, arcId, insistence]
  )

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        p: 3,
        minHeight: '100vh',
        display: 'flex',
        'flex-direction': 'column',
      }}
    >
      <Box>
        <TextField
          inputProps={{ style: { fontSize: '2rem' } }}
          InputLabelProps={{ style: { fontSize: '2rem' } }}
          sx={{ mb: 3 }}
          variant="standard"
          placeholder="title"
          autoFocus={!title}
          fullWidth
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          onBlur={(e) => {
            if (e.target.value !== insistence.db.arc[arcId].title) {
              insistence.pushChange({
                op: 'put',
                path: ['arc', arcId, 'title'],
                data: e.target.value,
              })
              navigate(`/arc/${quotePlus(e.target.value)}-${arcId}`, {
                replace: true,
              })
            }
          }}
        />
      </Box>

      <Box>
        {seq.map(([seqId, name]) => (
          <DraggableTOCItem
            key={seqId}
            name={name}
            onDrop={onDrop}
            seqId={seqId}
            onDelete={() => {
              console.log('DELETE')
              const nextSeqId = insistence.db.arc[arcId].seq[seqId]._NEXT

              if (seqRaw._HEAD === seqId) {
                // set _HEAD to nxt
                insistence.pushChange({
                  op: nextSeqId ? 'put' : 'delete',
                  path: ['arc', arcId, 'seq', '_HEAD'],
                  data: nextSeqId,
                })
              } else {
                // Find prev and set its next entry
                let prevSeqId = seqRaw._HEAD
                while (prevSeqId) {
                  const prevNode = seqRaw[prevSeqId]
                  if (prevNode._NEXT === seqId) {
                    insistence.pushChange({
                      op: nextSeqId ? 'put' : 'delete',
                      path: ['arc', arcId, 'seq', prevSeqId, '_NEXT'],
                      data: nextSeqId,
                    })
                    break
                  }
                  prevSeqId = prevNode._NEXT
                }
              }

              insistence.pushChange({
                op: 'delete',
                path: ['arc', arcId, 'seq', seqId],
              })
            }}
          />
        ))}
      </Box>

      <FragChooser
        onSelect={onDrop}
        helpText={
          seq.length
            ? ''
            : 'Add fragments to the arc by dragging from the sidebar or clicking "+"'
        }
      />

      <Divider />

      {seq.map(([seqId, uid]) => (
        <Fragment key={uid} uid={uid} />
      ))}
    </Box>
  )
}

const router = createBrowserRouter([
  {
    path: '/',
    element: <Main />,
    children: [
      { path: '', element: <AllCards /> },
      { path: 'arc/:arcId', element: <Arc /> },
      // deprecated
      { path: 'frag/:fragId', element: <FragmentPage /> },
    ],
  },
])

function App() {
  // Set based on system, but allow overriding?
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')
  const [mode, setMode] = React.useState(prefersDarkMode ? 'dark' : 'light')

  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'))
      },
    }),
    []
  )

  const theme = createTheme({
    palette: { mode },
    typography: {
      writing: {
        fontSize: '1.25rem',
        color: mode === 'light' ? 'black' : 'white',
        fontFamily: "Times, 'Times New Roman', serif",
        marginBottom: '1.5rem',
        display: 'block',
      },
    },
  })

  return (
    <GoogleOAuthProvider clientId="463130576860-9lc7qa1dcknqprcspoe15m27f3trhn3m.apps.googleusercontent.com">
      <DndProvider backend={HTML5Backend}>
        <ColorModeContext.Provider value={colorMode}>
          <ThemeProvider theme={theme}>
            <Box sx={{ display: 'flex' }}>
              <CssBaseline />
              <DataLoader>
                <RouterProvider router={router} />
              </DataLoader>
            </Box>
          </ThemeProvider>
        </ColorModeContext.Provider>
      </DndProvider>
    </GoogleOAuthProvider>
  )
}

export default App
